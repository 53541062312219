<template>
  <fullscreen class="check h-100" ref="fullscreen" @change="fullscreenChange" background="#8bacbd">
    <div class="logo-wrapper position-absolute text-center text-white cursor-pointer" @click="toggleFullscreen">
      <div class="logo-content bg-white rounded-circle border border-secondary">
        <img src="../../assets/tornhuset.png" class="logo m-1 rounded-circle" alt="Agnes">
      </div>
    </div>

    <scanner :show-buttons="false" :success-phrase="successPhrase" :voucher-count-phrase="voucherCountPhrase" :scan-phrase="scanPhrase"></scanner>

    <button type="button" id="signOut" class="btn btn-danger position-absolute" @click="signOut" v-if="!fullscreen">Logga ut</button>
  </fullscreen>
</template>

<script>
  import { firebase } from "@/initializers/firebase.js"
  
  import Scanner from "@/frontend/components/scanner.vue"

  export default {
    name: "check",
    components: { Scanner },
    data() {
      return {
        fullscreen: false,
        successPhrase: "%{name}",
        voucherCountPhrase: "Du har %{voucherCount} kvar",
        scanPhrase: "Saldofråga"
      }
    },
    methods: {
      toggleFullscreen() {
        this.$refs["fullscreen"].toggle()
      },
      fullscreenChange(fullscreen) {
        this.fullscreen = fullscreen
      },
      signOut () {
        firebase.auth().signOut().then(() => {
          this.$router.replace({ name: "auth" })
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .check {
    background-color: #8bacbd;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .logo-wrapper {
    top: 20px;
    left: 20px;
    text-transform: uppercase;
    font-weight: 400;

    .logo-content {
      border-width: .2rem !important;
    }

    .logo {
      max-width: 53px;
    }
  }

  #signOut {
    bottom: 20px;
    right: 20px;
  }
</style>